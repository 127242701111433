.main {
  min-height: 100vh;
}
.login_container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-form {
  border: 1px solid #eee;
  border-radius: 0.5em;
  padding: 1rem;
  max-width: 400px;
  width: 400px;
  /* background: rgb(164, 189, 245); */
}
.login-form-button {
  display: block;
  margin: 1em auto;

  min-width: 30%;
}
.header {
  background: #fff;
  /* padding: 2rem; */
  padding-left: 1rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
  /* style={{ background: "#fff", padding: 0 }} */
}
.sider {
}
.sider .logo {
  height: 4rem;
}
.content {
  margin: 24px 16px;
  padding: 24px;
  background: #fff;
  min-height: 280px;
}

.ql-container {
  height: auto !important;
}

.ql-editor {
  min-height: 200px !important;
}

.btn-green {
  background-color: #188038;
  color: #fff !important;
}

.btn-green:hover {
  background-color: #188038;
  color: #fff !important;
  border: 1px solid #188038;
}

.btn-dull-green {
  background-color:  #17231a;
  color: #fff !important;
  border: 1px solid  #17231a;
}

.btn-dull-green:hover {
  background-color: #17231a;
  color: #fff !important;
  border: 1px solid #188038;
}

.badge {
  border-radius: 3px;
  width: fit-content;
  color: white;
  padding: 2px 7px;
  margin-right: 10px;
  height: fit-content;
  vertical-align: middle;
  text-align: center;
}
/* tile uploaded pictures */
.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.upload-list-inline .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}
