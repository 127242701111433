.preview-container {
  margin-bottom: 15px;

  .preview-player {
    height: 200px;
    width: 100%;
  }
}

.thumbnail-container {
  height: 200px;

  .ant-upload-picture-card-wrapper,
  .ant-upload,
  .ant-upload-select-picture-card {
    height: 100%;
    width: 100%;
  }
}

.video-container {
  height: 100px;

  .ant-upload-picture-card-wrapper,
  .ant-upload,
  .ant-upload-select-picture-card {
    height: 100%;
    width: 100%;
  }
}

